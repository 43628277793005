import React, { useState, useEffect, useRef } from 'react';

import { makeStyles } from '@material-ui/core/styles';

import Paper from '@material-ui/core/Paper';
import Popper from '@material-ui/core/Popper';
import Button from '@material-ui/core/Button';
import ButtonGroup from '@material-ui/core/ButtonGroup';
import ClickAwayListener from '@material-ui/core/ClickAwayListener';
import Grow from '@material-ui/core/Grow';
import MenuItem from '@material-ui/core/MenuItem';
import MenuList from '@material-ui/core/MenuList';

import ArrowDropDownIcon from '@material-ui/icons/ArrowDropDown';

import { useParams, useLocation, useHistory } from 'react-router-dom';
import { useTranslation } from 'react-i18next';
import get from 'lodash.get';
import { useSnackbar } from 'notistack';

import { EmptyStateHandleHasFeedback } from '@/components/App';
import { useAppContext } from '@/components/AppContext';
import { useDataSourceContext } from '@/components/DataSources';
import FeedbackExpansionPanel from './FeedbackExpansionPanel';
import FeedbackListFilters from './FeedbackListFilters';
import FeedbackDeleteDialog from './FeedbackDeleteDialog';
import FeedbackShareDialog from './FeedbackShareDialog';
import FeedbackWebHookDialog from './FeedbackWebHookDialog';
import FeedbackExportDialog from '@/components/FeedbackExports/FeedbackExportDialog';
import { WebHookContextProvider } from '@/components/WebHook';
import ShareMenu from './ShareMenu';
import { AddTagsDialog } from '@/components/Tags';
import { SelectionAppBar } from '@/components/SelectionToolbar';
import { filterDrawerBreakpoint } from '@/components/Layout/constants';
import {
	Grid,
	Loader,
	ActionButton,
	ActionIconButton,
	Link,
	KnowledgeBaseLink,
	ExpansionPanel,
	ExpansionPanelSummary,
} from '@/components/Layout';
import {
	useFilteredList,
	ListWithSidebar,
	ListFilterProvider,
	ListControls,
} from '@/components/FilteredLists';
import { ActionAddDialog } from '@/components/Actions';
import { Pagination } from '@/components/Pagination';
import { triggerDownload } from '@/utils';
import { useQuery, usePrevious, useEffectSkipInitialRender } from '@/hooks';
import { useEFM, AjaxForm } from '@/components/Ajax';
import { useOnboardingContext } from '@/components/Onboarding';
import {
	useHasPermission,
	NoPermissionTooltip,
	permissionModules,
	permissionSubjects,
} from '@/components/Permission';

const useStyles = makeStyles(theme => ({
	emptyIcon: {
		color: theme.palette.text.secondary,
		fontSize: theme.typography.pxToRem(64),
	},
	paginationSummary: {
		margin: 0,
	},
	spaceLink: {
		marginLeft: theme.spacing(0.5),
	},
	spaceLeft: {
		marginLeft: theme.spacing(1),
	},
	exportList: {
		zIndex: 1000,
	},
	emptyState: {
		minHeight: '50vh',
		whiteSpace: 'pre-line',
	},
}));

export default function FeedbackList({
	customFilters = [],
	noControls,
	noFilters,
	feedback_ids,
	mergeFeedback,
	surveyId,
	noPersist,
	inDrawer,
	feedback: feedbackFromProps = null,
	loadFeedback: loadFeedbackFromProps = null,
	...props
}) {
	const { filters: filtersFromProps = {} } = props;
	const { t } = useTranslation();
	const { enqueueSnackbar } = useSnackbar();
	const { app } = useAppContext();
	const { datasource } = useDataSourceContext();
	const classes = useStyles();
	const onboarding = useOnboardingContext();
	const query = useQuery();
	const [pageIdState, setPageIdState] = useState(1);

	const hasInboxEditPermission = useHasPermission();
	const hasExportEditPermission = useHasPermission({
		module: permissionModules.reporting,
		subject: permissionSubjects.exports,
	});
	const hasActionEditPermission = useHasPermission({
		module: permissionModules.actions,
		subject: permissionSubjects.action_management,
	});

	const exportOptions = [t`Quick export`, t`Export from template`, t`Advanced export`];
	const exportTrackEvents = [
		'export_quick_export',
		'export_from_template',
		'export_advanced_export',
	];
	const [exportOptionsOpen, setExportOptionsOpen] = useState(false);
	const exportAnchorRef = useRef(null);
	const [exportSelectedIndex, setExportSelectedIndex] = useState(0);

	const exportOptionsClick = (e, index) => {
		setExportSelectedIndex(index);
		setExportOptionsOpen(false);
		setDialogOpen('export');
	};

	const closeExportMenu = e => {
		if (exportAnchorRef.current && exportAnchorRef.current.contains(e.target)) {
			return;
		}
		setExportOptionsOpen(false);
	};

	useEffect(() => {
		if (query.get('download')) {
			const downloadUrl = `/download/download-export?token=${query.get('download')}`;
			triggerDownload(downloadUrl);

			enqueueSnackbar(
				<>
					{t`Your export download should have started. If it didn't`}
					<Link
						color="secondary"
						className={classes.spaceLink}
						href={downloadUrl}
						download
						fontWeight="bold"
					>{t`click here to download your export`}</Link>
					.
				</>,
				{
					autoHideDuration: 30000,
				}
			);
		}
	}, [query.get('download')]);

	const [selected, setSelected] = useState([]);
	const [allSelected, setAllSelected] = useState(false);

	const [expanded, setExpanded] = useState('');
	const [prevExpanded, setPrevExpanded] = useState('');

	const [sidebar, setSidebar] = useState({
		open: window.innerWidth >= filterDrawerBreakpoint && !noControls,
		persist: window.innerWidth >= 960 && !noControls,
	});
	const [dialogOpen, setDialogOpen] = useState('');

	const filterState = useFilteredList({
		filters: {
			comment: '',
			email: '',
			actions: '',
			search: '',
			notes: '',
		},
	});

	const {
		filters,
		other,
		sort,
		order,
		queryFilters,
		setSingleFilter,
		setCustomFilter,
		clearAllFilters,
		hasFilters,
	} = filterState;

	const [selectedHook, setSelectedHook] = useState(false);
	const [mousePos, setMousePos] = useState({ mouseY: null, mouseX: null });
	const [shareAnchor, setShareAnchor] = useState(false);

	useEffect(() => {
		setCustomFilter({ action: 'set', custom: customFilters });
	}, [JSON.stringify(customFilters)]);

	useEffect(() => {
		Object.keys(filtersFromProps).forEach(key => {
			setSingleFilter(key, filtersFromProps[key]);
		});
	}, [filtersFromProps]);

	useEffect(() => {
		if (surveyId) {
			setSingleFilter('survey_id', surveyId, true);
		}
	}, [surveyId]);

	useEffect(() => {
		if (query.get('survey_id')) {
			setSingleFilter('survey_id', query.get('survey_id'), true);
		}
	}, [query.get('survey_id')]);

	const { pageId } = useParams();
	const location = useLocation();
	const history = useHistory();

	useEffect(() => {
		if (location?.state?.customFilters || location?.state?.filters) {
			clearAllFilters();
		}

		if (location?.state?.customFilters) {
			setCustomFilter({ action: 'set', custom: location.state.customFilters });
		}

		if (location?.state?.filters) {
			Object.keys(location.state.filters).forEach(key => {
				setSingleFilter(key, location.state.filters[key]);
			});
		}

		if (location?.state?.surveyId) {
			setSingleFilter('survey_id', location.state.surveyId, true);
		}
	}, [JSON.stringify(location.state)]);

	const whichPageId = inDrawer ? pageIdState : pageId;
	const currentFilterSet = {
		filters: {
			...filters,
			...(feedback_ids && { feedback_ids }),
			queryFilters,
		},
		...(other.survey_id && { survey_id: other.survey_id }),
		sort,
		order,
	};

	const prevCurrentFilterSet = usePrevious(currentFilterSet);

	const [data, loading, error, loadData] = useEFM(
		`/dashboard/inbox/list${
			whichPageId && whichPageId != 1 ? '/page/' + whichPageId : ''
		}`,
		currentFilterSet,
		feedbackFromProps !== null //dont fetch if feedback is passed from props
	);

	useEffect(() => {
		//sometimes, not all demo data is loaded yet, try to reload the data
		if (
			onboarding.trialStep === 4 &&
			!onboarding.trialMenuOpen &&
			data?.feedbackRows?.length < 5
		) {
			loadFeedback();
		}
	}, [onboarding.trialStep, onboarding.trialMenuOpen, data]);

	//reset pagination to page 1 if filters change
	const prevFromDate = usePrevious(app.date.fromDate);
	const prevToDate = usePrevious(app.date.toDate);

	useEffectSkipInitialRender(() => {
		const filtersChanged =
			JSON.stringify(currentFilterSet) !== JSON.stringify(prevCurrentFilterSet);
		const datesChanged =
			prevFromDate !== app.date.fromDate || prevToDate !== app.date.toDate;
		if ((filtersChanged || datesChanged) && whichPageId && Number(whichPageId) !== 1) {
			if (inDrawer) {
				setPageIdState(1);
			} else {
				history.push(`/reporting/inbox`);
			}
		}
	}, [
		whichPageId,
		inDrawer,
		currentFilterSet,
		prevCurrentFilterSet,
		app.date.fromDate,
		app.date.toDate,
		prevFromDate,
		prevToDate,
	]);

	function loadFeedback() {
		if (typeof loadFeedbackFromProps === 'function') {
			loadFeedbackFromProps();
		} else {
			loadData();
		}
	}

	function feedbackItems() {
		if (feedback_ids) {
			return feedback_ids
				.split(',')
				.map(id => {
					const item = get(data, 'feedbackRows', []).find(
						rowItem => rowItem.feedback_id == id
					);

					//If the mergeFeedback prop is passed, check if it contains an entry for the found item
					//if so merge the items and return the value otherwise just return the found item
					if (mergeFeedback && mergeFeedback[id]) {
						return {
							...item,
							...mergeFeedback[id],
						};
					}

					return item;
				})
				.filter(Boolean);
		}

		if (feedbackFromProps) {
			return feedbackFromProps;
		}

		return get(data, 'feedbackRows', []);
	}

	function toggleSelectAll(on) {
		setAllSelected(on);
		setSelected(on ? feedbackItems().map(item => item.feedback_id) : []);
	}

	function toggleSelect(id) {
		setSelected(items => {
			if (items.indexOf(id) === -1) {
				return [...items, id];
			} else {
				return items.filter(itemId => itemId != id);
			}
		});
	}

	function toggleSidebar() {
		setSidebar(state => ({
			...state,
			open: !state.open,
		}));
	}

	function toggleExpand(id) {
		if (expanded !== id) {
			setPrevExpanded(expanded);
			setExpanded(id);
		} else {
			setExpanded('');
			setPrevExpanded(id);
		}
	}

	return (
		<WebHookContextProvider>
			{({ state }) => (
				<>
					<ListFilterProvider
						{...filterState}
						singleFilters={[
							'comment',
							'email',
							'actions',
							'survey_id',
							'search',
							'notes',
						]}
						multiFilters={['tags', 'devices', 'browsers', 'os']}
						queryFilters={['queryFilters']}
						filterLabels={{
							tags: {
								label: t('Tags'),
								withouttags: t('Without tags'),
							},
							comment: {
								label: t('Open comment'),
								'>': t('With comment'),
								'=': t('Without comment'),
							},
							devices: {
								label: t('Device'),
								desktop: 'Desktop',
								tablet: 'Tablet',
								mobile: 'Mobile',
							},
							browsers: {
								label: t('Browser'),
								chrome: 'Chrome',
								safari: 'Safari',
								edge: 'Edge',
								internet_explorer: 'Internet Explorer',
								opera: 'Opera',
								firefox: 'Firefox',
								other: 'Other',
							},
							os: {
								label: t('Operating system'),
								windows: 'Windows',
								macos: 'macOS',
								ios: 'iOS',
								android: 'Android',
								chromeos: 'ChromeOS',
								linux: 'Linux',
								other: 'Other',
							},
							email: {
								label: t('Sent email replies'),
								'>': t('With sent email replies'),
								'=': t('Without sent email replies'),
							},
							actions: {
								label: t('Actions'),
								'>': t('With actions'),
								'=': t('Without actions'),
							},
							notes: {
								label: t('reporting-feedback_list-notes_filter-label'),
								'>': t('reporting-feedback_list-notes_filter-with_notes'),
								'=': t('reporting-feedback_list-notes_filter-without_notes'),
							},
						}}
					>
						<ListWithSidebar
							drawerContent={<FeedbackListFilters toggleFilterDrawer={toggleSidebar} />}
							noDrawer={noControls || noFilters}
							open={datasource.forms.asArray.length > 0 ? sidebar.open : !sidebar.open}
							onClose={toggleSidebar}
						>
							{!noControls && (
								<Grid item>
									<ListControls
										withSelectAll
										onSelectAllChange={e => toggleSelectAll(e.target.checked)}
										selectAllChecked={allSelected}
										onRefresh={loadFeedback}
										toggleFilterDrawer={toggleSidebar}
										sortOptions={[
											{ label: t('Date: new to old'), value: 'feedback_created-desc' },
											{ label: t('Date: old to new'), value: 'feedback_created-asc' },
											{ label: t('Customer name: A to Z'), value: 'customer_name-asc' },
											{ label: t('Customer name: Z to A'), value: 'customer_name-desc' },
										]}
										noFilters={noFilters}
									>
										<>
											<NoPermissionTooltip>
												<ButtonGroup
													size="small"
													ref={exportAnchorRef}
													aria-label="split button"
													disabled={!hasExportEditPermission}
												>
													<Button
														onClick={() => setExportOptionsOpen(prevOpen => !prevOpen)}
													>
														{t`Export feedback`}
													</Button>
													<Button
														size="small"
														aria-controls={
															exportOptionsOpen ? 'split-button-menu' : undefined
														}
														aria-expanded={exportOptionsOpen ? 'true' : undefined}
														aria-label="select export type"
														aria-haspopup="menu"
														onClick={() => setExportOptionsOpen(prevOpen => !prevOpen)}
													>
														<ArrowDropDownIcon />
													</Button>
												</ButtonGroup>
											</NoPermissionTooltip>
											<Popper
												open={exportOptionsOpen}
												anchorEl={exportAnchorRef.current}
												role={undefined}
												transition
												disablePortal
												className={classes.exportList}
											>
												{({ TransitionProps, placement }) => (
													<Grow
														{...TransitionProps}
														style={{
															transformOrigin:
																placement === 'bottom' ? 'center top' : 'center bottom',
														}}
													>
														<Paper>
															<ClickAwayListener onClickAway={closeExportMenu}>
																<MenuList>
																	{exportOptions.map((option, index) => (
																		<MenuItem
																			key={option}
																			data-track-event={exportTrackEvents[index]}
																			selected={index === exportSelectedIndex}
																			onClick={e => exportOptionsClick(e, index)}
																		>
																			{t(option)}
																		</MenuItem>
																	))}
																</MenuList>
															</ClickAwayListener>
														</Paper>
													</Grow>
												)}
											</Popper>
										</>
									</ListControls>
								</Grid>
							)}
							<Grid item>
								{loading && (
									<Loader
										sticky
										empty={feedbackItems().length === 0}
									/>
								)}
								{!loading && feedbackItems().length === 0 && (
									<EmptyStateHandleHasFeedback
										className={classes.emptyState}
										image={<img src={`/assets/img/r/emptystate/feedback_inbox.gif`} />}
										primaryNoSurvey={t`reporting-feedback_inbox-emptystate_no_survey-title`}
										primaryNoFeedback={t`reporting-feedback_inbox-emptystate_no_feedback-title`}
										primary={
											hasFilters
												? t`reporting-feedback_inbox-emptystate_filter-title`
												: t`reporting-feedback_inbox-emptystate_default-title`
										}
										secondaryNoSurvey={t`reporting-feedback_inbox-emptystate_no_survey-text`}
										secondaryNoFeedback={t`reporting-feedback_inbox-emptystate_no_feedback-text`}
										secondary={
											hasFilters
												? t`reporting-feedback_inbox-emptystate_filter-text`
												: t`reporting-feedback_inbox-emptystate_default-text`
										}
										noSurveyTrackEvent="feedbackinbox_emptystate_create_form"
										actionNoFeedback={
											<KnowledgeBaseLink
												dataTrackEvent="feedbackinbox_emptystate_knowledgebase"
												page="feedback_inbox"
												variant="body1"
											>
												{t`reporting-feedback_inbox-emptystate_no_feedback-link`}
											</KnowledgeBaseLink>
										}
									/>
								)}
								{feedbackItems().map(item => (
									<FeedbackExpansionPanel
										{...item}
										key={item.feedback_id}
										toggleSelection={e => toggleSelect(item.feedback_id)}
										selected={selected}
										allowShareEmail
										isSelected={selected.indexOf(item.feedback_id) > -1}
										expanded={item.feedback_id === expanded}
										toggleExpand={e => toggleExpand(item.feedback_id)}
										wasLastClicked={prevExpanded === item.feedback_id}
										addTagsToFeedback={loadFeedback}
										loadFeedback={loadFeedback}
										feedbackLoading={loading}
										feedbackList={feedbackItems()}
									/>
								))}
								{!noControls && feedbackItems().length > 0 && (
									<ExpansionPanel expanded={false}>
										<ExpansionPanelSummary
											classes={{ content: classes.paginationSummary }}
										>
											<Pagination
												{...data.pager}
												base="/reporting/inbox"
												pageId={inDrawer ? pageIdState : null}
												onChange={page => {
													setPageIdState(page);
												}}
											/>
										</ExpansionPanelSummary>
									</ExpansionPanel>
								)}
							</Grid>
						</ListWithSidebar>

						<SelectionAppBar
							text={selected.length + t` selected`}
							onClose={() => toggleSelectAll(false)}
							show={selected.length}
							fade
							color="default"
							disableInset={inDrawer}
						>
							{selected.length === 1 && (
								<>
									{(() => {
										const item = feedbackItems().find(
											item => item.feedback_id == selected[0]
										);
										if (item && item.customer && item.customer.email) {
											return (
												<NoPermissionTooltip>
													<ActionIconButton
														disabled={!hasInboxEditPermission}
														tooltip={t`Reply`}
														action="reply"
														onClick={() => setDialogOpen('reply')}
													/>
												</NoPermissionTooltip>
											);
										}
									})()}

									<ActionIconButton
										action="share"
										tooltip={t`Share`}
										onClick={e => {
											e.preventDefault();
											const { clientX, clientY } = e;
											setShareAnchor(e.currentTarget);
											setMousePos({
												mouseX: clientX - 2,
												mouseY: clientY - 4,
											});
										}}
									/>

									<NoPermissionTooltip
										module={permissionModules.actions}
										subject={permissionSubjects.action_management}
									>
										<ActionIconButton
											disabled={!hasActionEditPermission}
											action="add_action"
											tooltip={t`Add action`}
											onClick={() => setDialogOpen('action')}
										/>
									</NoPermissionTooltip>
								</>
							)}
							{selected.length > 1 &&
								(Array.isArray(state.hooks) ? state.hooks : []).filter(
									hook => hook.event_type === 'user_init'
								).length > 0 && (
									<NoPermissionTooltip>
										<ActionIconButton
											disabled={!hasInboxEditPermission}
											action="share"
											tooltip={t`Share`}
											onClick={e => {
												e.preventDefault();
												const { clientX, clientY } = e;
												setShareAnchor(e.currentTarget);
												setMousePos({
													mouseX: clientX - 2,
													mouseY: clientY - 4,
												});
											}}
										/>
									</NoPermissionTooltip>
								)}
							<NoPermissionTooltip>
								<ActionIconButton
									action="tags"
									tooltip={t`Add tags`}
									onClick={() => setDialogOpen('tags')}
									disabled={!hasInboxEditPermission}
								/>
							</NoPermissionTooltip>
							<NoPermissionTooltip>
								<ActionIconButton
									action="delete"
									tooltip={
										selected.length === 1
											? t`Delete selected feedback item`
											: t('Delete {{count}} selected feedback items', {
													count: selected.length,
											  })
									}
									onClick={() => setDialogOpen('delete')}
									data-test-element="feedback_list_actions_delete_feedback"
									disabled={!hasInboxEditPermission}
								/>
							</NoPermissionTooltip>

							<ActionIconButton
								action="new_window"
								tooltip={
									selected.length === 1
										? t`Open selected item in new window`
										: t`Open selected items in separate new windows, you might need to disable your popup blocker`
								}
								onClick={() => {
									selected.forEach(id =>
										setTimeout(() => window.open(`/r/reporting/inbox/detail/${id}`))
									);
								}}
							/>
						</SelectionAppBar>

						<ShareMenu
							shareMenuAnchor={shareAnchor}
							allowShareEmail={selected.length === 1}
							mouseX={mousePos.mouseX}
							mouseY={mousePos.mouseY}
							setShareDialogOpen={() => {
								setDialogOpen('share');
								setShareAnchor(null);
								setMousePos({ mouseX: null, mouseY: null });
							}}
							setWebHookDialogOpen={webhook_id => {
								setDialogOpen('webhook');
								setSelectedHook(webhook_id);
								setShareAnchor(null);
								setMousePos({ mouseX: null, mouseY: null });
							}}
							closeMenu={() => {
								setShareAnchor(null);
								setMousePos({ mouseX: null, mouseY: null });
							}}
						/>

						<AddTagsDialog
							open={dialogOpen === 'tags'}
							onClose={e => setDialogOpen('')}
							onSuccess={loadFeedback}
							feedback_ids={selected}
							dataTrackEvent="inbox_feedback_tags_added"
						/>
						<FeedbackDeleteDialog
							feedback_ids={selected}
							open={dialogOpen === 'delete'}
							close={e => setDialogOpen('')}
							onSuccess={() => {
								loadFeedback();
								setSelected([]);
							}}
						/>
						<ActionAddDialog
							//todo allow add multiple actions
							feedback_id={selected[0]}
							open={dialogOpen === 'action'}
							onClose={e => setDialogOpen('')}
							onSuccess={loadFeedback}
							//onDelete={this.loadFeedback}
						/>

						<FeedbackShareDialog
							open={['share', 'reply'].indexOf(dialogOpen) > -1}
							onClose={e => setDialogOpen('')}
							feedback_id={selected[0]}
							reply={dialogOpen === 'reply'}
							replyTo={(() => {
								const item = feedbackItems().find(
									rowItem => rowItem.feedback_id == selected[0]
								);
								if (item) {
									return item.customer.email;
								}
							})()}
							onSuccess={loadFeedback}
						/>

						<FeedbackWebHookDialog
							open={dialogOpen === 'webhook'}
							onClose={e => setDialogOpen('')}
							selectedHook={selectedHook}
							feedback_ids={selected}
							onSuccess={() => {
								loadFeedback();
							}}
						/>

						<FeedbackExportDialog
							open={dialogOpen === 'export'}
							variant={
								exportSelectedIndex === 0
									? 'quick'
									: exportSelectedIndex === 1
									? 'use_template'
									: 'single'
							}
							current_data={data}
							onClose={e => setDialogOpen('')}
							listFilters={currentFilterSet}
						/>
					</ListFilterProvider>
				</>
			)}
		</WebHookContextProvider>
	);
}
