import React from 'react';

import { useTranslation } from 'react-i18next';

import { makeStyles } from '@/styles';
import { Chip, Tooltip } from '@/components/Layout';

const useStyles = makeStyles(theme => ({
	trialChip: {
		position: 'absolute',
		top: 10,
		left: 10,
		zIndex: '1000',
	},
	trialChipInline: {
		marginLeft: theme.spacing(1),
	},
}));

export default function ProductTrialChip({ daysLeftInProductTrial = 0, inline }) {
	const classes = useStyles();
	const { t } = useTranslation();

	return (
		<Tooltip
			title={t(
				'data_collection-feedback_forms-editor-channel_trial-chip_trial_active-tooltip'
			)}
		>
			<Chip
				label={t(
					'data_collection-feedback_forms-editor-channel_trial-chip_trial_active',
					{
						count: daysLeftInProductTrial,
					}
				)}
				color="primary"
				size="small"
				className={!inline ? classes.trialChip : classes.trialChipInline}
			/>
		</Tooltip>
	);
}
