import React from 'react';
import { makeStyles } from '@material-ui/core/styles';

import Box from '@material-ui/core/Box';

import Typography from '../Layout/Typography';
import UpgradeButton from './UpgradeButton';

const useStyles = makeStyles(theme => ({
	overlay: {
		position: 'absolute',
		top: 0,
		left: 0,
		right: 0,
		bottom: 0,
		background: 'rgba(255,255,255,.80)',
		display: 'flex',
		flexDirection: 'column',
		justifyContent: 'center',
		alignItems: 'center',
		textAlign: 'center',
		padding: theme.spacing(1),
	},
	message: {
		marginBottom: theme.spacing(2),
	},
}));

export default function UpgradeOverlay({
	children,
	needsUpgrade,
	message = '',
	explainer,
	product,
	...props
}) {
	const classes = useStyles();

	return !needsUpgrade ? (
		children
	) : (
		<Box
			position="relative"
			{...props}
		>
			{children}
			<div className={classes.overlay}>
				<Typography variant="subtitle">{message}</Typography>
				{explainer}
				<UpgradeButton
					mt={3}
					data-track-event={`upgrade_from_upgrade_overlay_${product}`}
				/>
			</div>
		</Box>
	);
}
